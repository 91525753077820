// Note: Requires flexslider 2 buddy.Testimonial Flexslider
jQuery(document).ready(function ($) {
  $(".testimonial-wrapper .testimonial-flexslider").flexslider({
    animation: "fade",
    prevText: "",
    nextText: "",
    controlNav: true,
    directionNav: false,
  });
}); /* end of as page load scripts */
